<template>
    <div class="video-stream-container shadow-sm"
        :ref="containerRef"
        @mouseover="videoHovered = true"
        @mouseleave="videoHovered = false"
    >
        <video  
            width="100%"
            :ref="videoRef"
            autoplay 
            controls
            muted=""
        >
        </video>
        <!-- Custom controls -->
        <div class="video-custom-controls"
            :class="{'is-fullscreen': isFullscreen, 'show-controls': videoHovered}" 
            v-show="showCustomControls"
        >
            <div class="control-left">
                <!-- LIVE -->
                <div class="live">
                    <span>LIVE</span>
                </div>
                <!-- volume control -->
                <div
                    v-if="!stream.is_audio_muted"
                    class="volume-controls d-flex align-items-center"
                >
                    <i class="fas" :class="updateVolumeIcon()"  @click="toggleMute"></i>              
                    <input 
                        v-model="volume"
                        @input="updateVolume"
                        class="volume-input"  
                        type="range" 
                        max="1" 
                        min="0" 
                        step="0.01"
                    />
                </div>
            </div>
            <div class="control-right">
                <!-- resolution -->
                <div class="resolutions" v-if="hasResolution">
                    <i class="fas fa-cog" 
                        @click="showResolution = !showResolution" 
                        v-click-outside="hideResolutions"
                        :class="{'text-white': showResolution}"
                    ></i>
                    <div class="available-resolutions-container" 
                        :class="{'show-resolutions': showResolution}"
                    >
                        <ul class="list-unstyled">
                            <li v-for="(resolution, index) in resolutions" 
                                :key="index"
                                :class="{'selected': resolution.is_active}"
                                @click="changeResolution(resolution.link)"
                            >
                                {{ resolution.title }}
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- PIP -->
                <i class="far fa-window-restore detach-screen"
                    @click="customPIP"
                >
                </i>
                <!-- fullscreen -->
                <i class="fas fullscreen" 
                    :class="[isFullscreen ? 'fa-compress-arrows-alt': 'fa-expand']" 
                    @click="toggleFullScreen"
                >
                </i>
            </div>
        </div>

        <!-- pip overlay -->
        <StreamPlaceholder
            v-if="customPipOverlay"
            :placeholderDetails="PIPPlaceholderDetails"
        />
    </div> 
</template>

<script>
    import Hls from 'hls.js';
    import { EventBus } from '../../bus';
    import {mapMutations, mapGetters, mapActions} from 'vuex';
    import StreamPlaceholder from './StreamPlaceholder.vue';
    import clickOutside from "vue-click-outside";
    import api from '../../api';

    export default {
        props: {
            stream: {
                type: Object,
                required: true
            },
            videoRef: {
                type: String,
                required: true
            },
            containerRef: {
                type: String,
                required: true
            },
        },
        components: {
            StreamPlaceholder
        },
        data() {
            return {
                video: setTimeout(() => { null }, 1000),
                isFullscreen: false,
                volume: 0.2,
                showResolution: false,
                showCustomControls: false,
                videoHovered: false,
                
                customPipOverlay: false,
                PIPPlaceholderDetails: null,
                hls: null
            }
        },
        mounted() {
            this.video = this.$refs[this.videoRef];
            
            this.hls = new Hls();
            this.hls.loadSource(this.stream.src);
            this.hls.attachMedia(this.video);
            this.hls.attachMedia(this.video);
            this.hls.onloadeddata = (event) => {
              this.updateToLastBufferTime();
              this.video.play();
            };

            this.video.onplay = (event) => {
              this.updateToLastBufferTime();
            };

            const videoWorks = !!document.createElement('video').canPlayType;

            if (videoWorks) {
                this.video.controls = false;
                this.showCustomControls = true;
            }

            this.updateVolume();

            document.addEventListener('fullscreenchange', this.handleControlsOnFullscreen);
            document.addEventListener('webkitfullscreenchange', this.handleControlsOnFullscreen);
            document.addEventListener('mozfullscreenchange', this.handleControlsOnFullscreen);
            document.addEventListener('MSFullscreenChange', this.handleControlsOnFullscreen);

          // emit from StreamHeader
            EventBus.$on('delete-stream', (id) => {
                if (this.stream.id === id) {
                    this.deleteStream(id);
                    this.removeStream(id);
                }
            })
            EventBus.$on('stop-stream', id => {
                if (this.stream.id === id) {
                    this.deleteStream(id);
                }
            })

            // PIP
            EventBus.$on('back-to-normal', (id) => {
                if (this.stream.id === id) {
                    this.customPipOverlay = false;
                    this.video.muted = false;
                }
            })
        },
        computed: {
            ...mapGetters(["streamPIP", "getTranslationByKey"]),
            hasResolution() {
                return ("options" in this.stream && this.stream.options.length !== 0);
            },
            resolutions() {
                if ("options" in this.stream && this.stream.options.length !== 0) {
                    return this.stream.options
                }
            },
        },
        methods: {
            ...mapMutations(["setCustomPIP", "removeStream", "removeSinglePIP"]),
            ...mapActions(["replaceStream"]),
            updateVolumeIcon() {
                return {
                    'fa-volume-mute': this.video.muted || this.video.volume === 0,
                    'fa-volume-down': this.video.volume > 0 && this.video.volume <= 0.5,
                    'fa-volume-up'  : this.video.volume !== 0 && this.video.volume > 0.5,
                    'mr-2': this.isFullscreen
                };
            },
            toggleMute() {
                this.video.muted = !this.video.muted;

                if (this.video.muted) {
                    this.video.setAttribute('data-volume', this.volume);
                    this.volume = 0;
                } 
                else {
                    this.volume = this.video.dataset.volume;
                }
                
                this.video.volume = this.volume;
            },
            updateVolume() {
                if (this.video.muted) {
                    this.video.muted = false;
                }
                this.video.volume = this.volume;
            },
            hideResolutions() {
                this.showResolution = false;
            },
            updateToLastBufferTime() {
                this.video.currentTime = this.video.duration + 3; //3 - Seconds reserve that could be spent on buffer loading
            },

            async changeResolution(link) {
                try {
                    const response = await api.post(link);
                    if (response.status === 200) {
                        EventBus.$emit('stop-stream', this.stream.id);
                        this.replaceStream({
                            oldStreamID: this.stream.id,
                            newStream: response.data.stream
                        });
                    }
                } catch(e) {
                    this.handleErrorMixin(e);
                }
            },
            toggleFullScreen() {
                const videoContainer = this.$refs[this.containerRef];
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                } else if (document.webkitFullscreenElement) {
                    document.webkitExitFullscreen();
                } else if (videoContainer.webkitRequestFullscreen) {
                    videoContainer.webkitRequestFullscreen();
                } else {
                    videoContainer.requestFullscreen();
                }
            },
            customPIP() {
                this.customPipOverlay = true;
                this.video.muted = true;
                this.PIPPlaceholderDetails = {
                    message: this.getTranslationByKey('vehicles.stream.pic_in_pic'),
                    status: 'pip'
                };
                this.setCustomPIP({
                        id: this.stream.id,
                        title: this.stream.title,
                        src: this.stream.src,
                        sourceType: this.stream.source_type,
                        poster: this.video.poster,
                        time: this.video.currentTime
                    },
                );
            },
            handleControlsOnFullscreen(e) {
                if (!document.fullscreenElement && 
                    !document.webkitIsFullScreen && 
                    !document.mozFullScreen && 
                    !document.msFullscreenElement) {
                    this.isFullscreen = false;
                } else {
                    this.isFullscreen = true;
                }
            },
            async deleteStream(id) {
                this.hls.destroy();
                this.hls.off(Hls.Events.MEDIA_ATTACHED);
                this.hls.off(Hls.Events.MANIFEST_PARSED);
                this.hls.off(Hls.Events.LEVEL_LOADED);
                this.hls.off(Hls.Events.LEVEL_UPDATED);
                this.hls.off(Hls.Events.ERROR);

                if (this.streamPIP.length > 0) {
                    this.removeSinglePIP(id);
                }
            }
        },
        directives: {
            clickOutside,
        },
    }
</script>