<template>
  <div class="reset-pass-wrapper d-flex align-items-center page-container">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="card reset-pass-container pb-5 pb-lg-0 shadow">
            <div class="logo">
              <router-link :to="{name: 'objects'}">
                <img src="/img/smart_systems_logo.png" alt="Logo">
              </router-link>
            </div>
            <div class="card-header reset-pass-header text-center pb-4">
              <i class="fas fa-lock-open mr-2 mb-2 text-danger"></i>
              <h1>{{ getTranslationByKey('auth.reset_pass') }}</h1>
              <p class="small text-muted mb-0">{{ getTranslationByKey('auth.create_secure_msg') }}</p>
              <p class="small text-black-50">{{ getTranslationByKey('auth.pass_length_msg') }}</p>
              <!-- error message -->
              <transition name="fade">
                <p class="error" v-show="showMsg">
                  <span v-for="msg in responseMsg" :key="msg" class="d-block">{{ msg }}</span>
                </p>
              </transition>
            </div>

            <div class="card-body reset-pass-content mt-0">
              <!-- Password -->
              <div class="form-group row">
                <div class="col">
                  <div class="user-pass" :class="{'activated': passFieldActive}">
                    <input
                        v-model="password"
                        @focus="passFieldActive = true"
                        @blur="passFieldActive = false"
                        class="form-control mb-3"
                        :type="passTypeText ? 'text': 'password'"
                        :placeholder="getTranslationByKey('auth.new_pass')"
                    >
                    <i
                        class="fas js-show-pass"
                        :class="[passTypeText ? 'fa-eye-slash' : 'fa-eye']"
                        v-show="password.length"
                        @click="passTypeText = !passTypeText"
                    ></i>
                  </div>
                </div>
              </div>
              <!-- Confirm pass -->
              <div class="form-group row">
                <div class="col">
                  <div class="user-pass-confirm" :class="{'activated': passConfirmActive}">
                    <input
                        v-model="passwordConfirm"
                        @focus="passConfirmActive = true"
                        @blur="passConfirmActive = false"
                        class="form-control mb-3"
                        :type="passConfirmTypeText ? 'text' : 'password'"
                        :placeholder="getTranslationByKey('auth.re_type_pass')"
                    >
                    <i
                        class="fas js-show-pass"
                        :class="[passConfirmTypeText ? 'fa-eye-slash' : 'fa-eye']"
                        v-show="passwordConfirm.length"
                        @click="passConfirmTypeText = !passConfirmTypeText"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col d-flex justify-content-center mb-3">
                  <button
                      @click="resetPassword"
                      type="submit"
                      class="btn reset-button minor-text w-75"
                  >
                    <i class="fas mr-1" :class="[loading ? 'fa-sync-alt fa-spin' : 'fa-check']"></i>
                    <span>{{ getTranslationByKey('auth.reset_pass') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      passFieldActive: false,
      passConfirmActive: false,
      passTypeText: false,
      passConfirmTypeText: false,
      password: '',
      passwordConfirm: '',
      showMsg: false,
      responseMsg: [],
      success: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(["getTranslationByKey"]),
  },
  methods: {
    resetPassword() {
      this.loading = true;
      api.post('password/reset', {
        token: this.$route.params.token,
        email: this.$route.query.email,
        password: this.password,
        password_confirmation: this.passwordConfirm
      }).then(response => {
        const responseData = response.data;
        if (responseData.success) {
          this.$router.push({ name: "login"})
        }
        this.loading = false;
      }).catch(error => {
        this.responseMsg = error.response.data.errors.password;
        this.showMsg = true;
        this.loading = false;
        console.error(error);
      });
    }
  }
}
</script>
