<template>
  <div id="user-loginas-list" class="info-window">
    <div class="sticky d-flex align-items-center justify-content-between border-0 mb-1 px-2 shadow-sm">
      <i 
          class="fa fa-chevron-circle-right close-slide-container-icon"
          @click="closeComponent"
      ></i>
      <h5 class="slide-heading flex-grow-1 text-center">
          <i class="fas fa-users mr-1 text-success"></i>
          {{ getTranslationByKey('navbar.user_dropdown.login_as.login_as')  }}
      </h5>
    </div>
    <div class="login-as-list-container">
      <SearchInput 
        v-model="treeFilter" 
        class="mb-1"
        :placeholder="getTranslationByKey('navbar.user_dropdown.login_as.find')"
      />

      <tree :data="loginTreeView"
            :filter="treeFilter"
            :options="treeOptions"
            v-if="loginTreeView.length"
            @node:selected="loginAs"
      >
        <div slot-scope="{ node }">
          <template>
            <div 
              v-if="node.data.type == 'user' || isEmptyCustomer(node)" 
              :title="`${node.data.email} ${node.text ? ('(' + node.text + ')'): ''}`" 
              :class="isCustomerClass(node)"
            >
              <template v-if="node.data.email">{{ node.data.email }} <template v-if="node.text">({{ node.text }})</template></template>
              <template v-if="!node.data.email">{{ node.text }}</template>
            </div>
            <!-- parent -->
            <span v-else>{{ node.text }}</span>
          </template>
        </div>
      </tree>
    </div>
    <div
        class="spinner-grow text-primary float-right mr-3 mt-n1 loading"
        role="status"
        v-if="!loginTreeView.length"
    >
      <span class="sr-only">{{ getTranslationByKey('vehicles.loading') }}</span>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import api from "../../api";
import LiquorTree from "liquor-tree";
import SearchInput from "../global/SearchInput.vue";

export default {
  components: {
    [LiquorTree.name]: LiquorTree,
    SearchInput
  },
  data() {
    return {
      loginTreeView: [],
      treeFilter: '',
      treeOptions: {
        filter: {
          emptyText: '',
          matcher(query, node) {
            return new RegExp(query, 'i').test(node.data.email) || 
            new RegExp(query, 'i').test(node.text)
          },
          showChildren: true
        },
        propertyNames: {
          text: 'title',
          children: 'items'
        }
      },
    };
  },
  computed: {
    ...mapGetters(["getTranslationByKey"]),
    isEmptyCustomer() {
      return (node) => {
        return node.data.type == 'customer' && node.children.length == 0;
      }
    },
    isCustomerClass() {
      return (node) => {
        return node.data.type == 'customer' ? 'font-weight-600 text-dark': '';
      }
    }
  },
  created() {
    this.getUsers();
    this.treeOptions.filter.emptyText = this.getTranslationByKey('navbar.user_dropdown.login_as.no_results')
  },
  async mounted() {
    this.treeOptions.filter.emptyText = this.getTranslationByKey('vehicles.objects_filter.nothingfound')
  },
  methods: {
    ...mapActions(["setUser"]),
    async getUsers() {
      try {
        const response = await api.get("customers", {
          params: {
            with: 'users'
          }
        })

        this.loginTreeView = response.data
        
      } catch (e) {
        this.handleErrorMixin(e)
      }
    },
    async loginAs(node) {
      const userId = node.id;
      if (node.data.type == "customer") {
        return;
      }

      try {
        const response = await api.post('login-as', {
          id: userId,
        })
        const responseData = response.data.data;

        if (responseData.token) {
          this.setUser({
            auth: true,
            token: responseData.token,
            user: responseData.user
          });
          this.$router.go(this.$router.currentRoute);
        } else {
          localStorage.removeItem('user');
          this.$router.push({name: 'login', query: {error: responseData}});
        }
      } catch (e) {
        this.handleErrorMixin(e)
        console.error(e)
      }
    },
    closeComponent() {
      this.$emit("close-component");
    },
  },
};
</script>
