<template>
  <div class="object-card-content">
    <div class="render-event bg-light">
      <div class="fixed-width"><i class="fa fa-car car-icon"></i></div>
      <div class="objects-list-model">{{ object.model_name }}</div>
      <span class="objects-list-time">{{ object.diff_time }}</span>
      <span class="objects-list-speed" v-show="object.speed > 0">{{
        speed
      }}</span>
      <span class="objects-list-course"></span>
      <div class="clear-both"></div>
    </div>
    <!-- object additional status -->
    <div class="object-status bg-light" v-if="shwoAddicionalStatus">
      <div class="fixed-width">
        <i
          class="fas status-icon"
          :class="object.status_icon"
          :style="{ color: object.status_color }"
        ></i>
      </div>
      <div class="test">{{ object.status_text }}</div>
    </div>
    <div class="timer">
      <div class="fixed-width">
        <div :class="object.last_msg_color" :title="getEventReasonTitle"></div>
      </div>
      <div class="object-event-reason">{{ reason }}</div>
      <div v-if="null !== object.event_time" class="object-event-time">
        {{ formatUserDateTime(object.event_time) }}
      </div>
    </div>
    <!-- location -->
    <div v-if="object.location"
      class="object-address-container"
      :title="getTranslationByKey('vehicles.tooltip.location.address')"
    >
      <span
        class="object-country-flag fixed-width"
        v-if="hasLocationCountry"
      >
        <img
          :src="'/img/flags/' + object.location.country.code + '.png'"
          alt=""
        />
      </span>
      <div class="object-address">
        <span v-show="object.location && object.location.address">
          {{ object.location.address.address }}
        </span>
        <span v-show="!object.location">{{ object.lat }} {{ object.lon }}</span>
      </div>
    </div>
    <!-- odometer & fuel level && battery voltage-->
    <div
      class="odometer-fuel-level d-flex align-items-center"
      v-if="object.can_odometer || object.can_fuel_level_1 || object.vcc"
    >
      <div
        class="odometer d-flex align-items-baseline"
        v-if="object.can_odometer"
        :title="getTranslationByKey('vehicles.tooltip.odometer_liter.odometer_title')"
      >
        <div class="fixed-width"><i class="fas fa-tachometer-alt"></i></div>
        {{ odometer }}
      </div>
      <div
        class="fuel-level d-flex align-items-baseline"
        v-if="object.can_fuel_level_1"
        :title="getTranslationByKey('vehicles.tooltip.odometer_liter.liter_title')"
      >
        <div class="fixed-width"><i class="fas fa-gas-pump"></i></div>
        {{ fuelLevel }}
      </div>
      <div 
        class="battery-voltage d-flex align-items-baseline"
        :class="isLowVCCBattery"
        v-if="object.vcc"
        :title="getTranslationByKey('vehicles.tooltip.odometer_liter.voltage')"
      >
        <div class="fixed-width">
          <i class="fa fa-car-battery" :class="isLowVCCBattery"></i>
        </div>
        {{ batteryVoltage }}
      </div>
    </div>
    <!-- / end odometer & fuel level -->
    <!-- temperature & seal -->
    <div
      class="d-flex flex-column temp-seal-container"
      v-if="object.temperature0 || object.temperature || object.seal"
    >
      <div
        class="temp-wrapper d-flex align-items-center"
        v-if="object.temperature0"
      >
        <div class="fixed-width">
          <i
            class="fas fa-temperature-high info-icon mt-1"
            :title="getTranslationByKey('vehicles.tooltip.temp.title')"
          ></i>
        </div>
        <div
          class="object-temp-seal"
          :class="{ outdated: object.temperature0.outdated }"
        >
          {{ "0 : " + object.temperature0.temperature }}&deg;
        </div>
      </div>
      <div
        class="temp-wrapper d-flex align-items-center"
        v-if="object.temperature"
      >
        <div class="fixed-width">
          <i
            class="fas fa-temperature-high info-icon my-1"
            :title="getTranslationByKey('vehicles.tooltip.temp.title')"
          ></i>
        </div>
        <div
          v-for="(temperature, key, index) in object.temperature"
          :key="index"
          class="object-temp-seal"
          :class="{
            outdated: temperature.outdated,
            lowbat: temperature.low_battery,
          }"
          :title="tempStatusTitle(temperature)"
        >
          {{ index + 1 + ": " + temperature.temperature }}&deg;
        </div>
      </div>
      <div class="seal-wrapper d-flex align-items-center" v-if="object.seal">
        <div class="fixed-width">
          <i
            class="fas fa-lock info-icon mb-1"
            :title="getTranslationByKey('vehicles.tooltip.seal.title')"
          ></i>
        </div>
        <div
          v-for="(seal, key, index) in object.seal"
          :key="index"
          class="object-temp-seal"
          :class="{ outdated: seal.outdated, lowbat: seal.low_battery }"
          :title="sealStatusTitle(seal)"
        >
          {{ index + 1 + ": " + seal.seal_id }}
        </div>
      </div>
    </div>
    <object-control-buttons :object="object"></object-control-buttons>
  </div>
</template>

<script>
import ObjectControlButtons from "./ObjectControlButtons.vue";
import helper from "../../helper";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
    ObjectControlButtons,
  },
  props: {
    object: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["settings", "getTranslationByKey", "user", "getUserTimezone"]),
    hasLocationCountry() {
      return this.object.location &&
          this.object.location.country &&
          this.object.location.country.valid &&
          this.object.location.country.valid !== undefined
    },
    speed() {
      if (helper.isSafari()) {
        return this.object.speed + " km/h";
      } else {
        return new Intl.NumberFormat(this.user.language, {
          style: "unit",
          unit: "kilometer-per-hour",
        }).format(this.object.speed);
      }
    },
    reason() {
      return  this.getTranslationByKey(`device_info.reason.${this.object.reason}`);
    },
    location() {
      if (this.object.location && this.object.location.lat && this.object.location.lng) {
        let location = this.object.location.lat + " " + this.object.location.lng;
        if (
            this.object.location.geozone.length &&
            this.object.location.geozone.valid !== undefined &&
            this.object.location.geozone.valid
        ) {
          location = this.object.location.geozone.name;
        } else if (
            this.object.location.address.valid !== undefined &&
            this.object.location.address.valid
        ) {
          location = this.object.location.address.address;
        }
        return location;
      }
      return '';
    },
    odometer() {
      if (helper.isSafari()) {
        return this.object.can_odometer + " km";
      } else {
        return new Intl.NumberFormat(this.user.language, {
          style: "unit",
          unit: "kilometer",
        }).format(this.object.can_odometer);
      }
    },
    fuelLevel() {
      if (helper.isSafari()) {
        return this.object.can_fuel_level_1 + " L";
      } else {
        return new Intl.NumberFormat(this.user.language, {
          style: "unit",
          unit: "liter",
        }).format(this.object.can_fuel_level_1);
      }
    },
    batteryVoltage() {
      return `${this.object.vcc} V`;
    },
    isLowVCCBattery() {
      return this.object.vcc && this.object.vcc <= this.settings.sm_low_vcc_level ? 'text-danger': '';
    },
    shwoAddicionalStatus() {
      return this.settings.sm_object_additional_status == 1 &&
        (this.object.status_text || this.object.status_icon)
        ? true
        : false;
    },
  },
  methods: {
    formatUserDateTime(time) {
      let utcTime = moment.utc(time);
      return moment(utcTime).tz(this.getUserTimezone).format("YYYY-MM-DD HH:mm:ss");
    },
    getEventReasonTitle() {
      return this.object.last_msg_color == "green"
        ? this.getTranslationByKey('vehicles.tooltip.timer.green')
        : this.getTranslationByKey('vehicles.tooltip.timer.offline');
    },
    tempStatusTitle(temp) {
      return temp.outdated
        ? this.getTranslationByKey('vehicles.tooltip.temp.outdated')
        : temp.low_battery
        ? this.getTranslationByKey('vehicles.tooltip.temp.lowbat')
        : this.getTranslationByKey('vehicles.tooltip.temp.title');
    },
    sealStatusTitle(seal) {
      return seal.outdated
        ? this.getTranslationByKey('vehicles.tooltip.seal.outdated')
        : seal.lowbat
        ? this.getTranslationByKey('vehicles.tooltip.seal.lowbat')
        : this.getTranslationByKey('vehicles.tooltip.seal.title');
    },
  },
};
</script>
