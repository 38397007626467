import api from "@/api";
import errorMixin from '../../mixins/handleErrorMixin';
export default {
  state: {
    authenticated: false,
    accessToken: null,
    user: {},
    sessionKill: false,
    uid: null,
    userOnlineStatus: null
  },
  actions: {
    setUser(context, data) {
      context.commit("SET_UID", data.uid);
      context.commit("setToken", data.token);
      context.commit("setAuth", data.auth);
      context.commit("setUser", data.user);
      localStorage.setItem('user', JSON.stringify(data));

      if (data.auth) {
        context.commit('SET_USER_ONLINE_STATUS', true);
      }
    },
    async checkUser(context) {
      await api.get("me").then((response) => {
        const data = response.data.data;
        const { streams } = response.data;
        if (streams.length > 0) {
          for (let stream of streams) {
            this.dispatch('WSStreamSubscription', stream);
          }
        }
        const user = JSON.parse(localStorage.getItem('user'));
        context.commit('setUserTimezone', response.data.user.timezone);
        user.user = data;
        context.commit("SET_UID", data.uid);
        context.commit("setUser", data);
        localStorage.setItem('user', JSON.stringify(user));
      }).catch((error) => {
        context.dispatch('removeAllIntervals');
        context.dispatch('setUser', { auth: false, token: null, user: {} });
        location.reload();
        errorMixin.methods.handleErrorMixin(error)
        context.dispatch('killSession')     
      });
    },
    async logout(context) {
      api
          .post("logout")
          .then(() => {
            context.dispatch('removeAllIntervals');
            context.dispatch('setUser', { auth: false, token: null, user: {} });
            location.reload();
          })
          .catch((err) => {
            errorMixin.methods.handleErrorMixin(err)
          });
          localStorage.removeItem('wazeData');
    },
    killSession({commit, dispatch}) {
      dispatch('setUser', { auth: false, token: null, user: {} });
      window.location.reload()
      commit('SET_SESSION_KILL_STATUS')
    },
    setUserOnlineStatus({ commit }, status) {
      commit('SET_USER_ONLINE_STATUS', status)
    }
  },
  mutations: {
    setAuth(state, data) {
      state.authenticated = data;
    },
    setToken(state, data) {
      state.accessToken = data;
    },
    SET_UID(state, data) {
      state.uid = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    SET_SESSION_KILL_STATUS(state) {
      state.sessionKill = true 
    },
    SET_USER_ONLINE_STATUS(state, status) {
      state.userOnlineStatus = status
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated;
    },
    accessToken(state) {
      return state.accessToken;
    },
    user(state) {
      return state.user;
    },
    isSessionKilled(state) {
      return state.sessionKill
    },
    getUid(state) {
      return state.uid;
    },
    isUserOnline(state) {
      return state.userOnlineStatus
    }
  },
};
