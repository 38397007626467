import store from './store/index';
import moment from 'moment-timezone';

export default {
  mapTypes() {
    const mapTypes = {
      osm: {
        name: "OpenStreetMap",
        minZoom: 6,
        maxZoom: 19,
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: function (coords, zoom) {
          return (
            "https://tile.openstreetmap.org/" +
            zoom +
            "/" +
            coords.x +
            "/" +
            coords.y +
            ".png"
          );
        },
      },
      waze: {
        name: "Waze",
        minZoom: 6,
        maxZoom: 19,
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: function (coords, zoom) {
          return (
            "https://worldtiles1.waze.com/tiles/" +
            zoom +
            "/" +
            coords.x +
            "/" +
            coords.y +
            ".png"
          );
        },
      },
      janaseta: {
        name: "Jāņa Sēta",
        minZoom: 6,
        maxZoom: 19,
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: function (coords, zoom) {
          return (
            "https://wms.kartes.lv/NMP6/wgs/15/" +
            zoom +
            "/" +
            coords.x +
            "/" +
            coords.y +
            ".png"
          );
        },
      },
      maplant: {
        name: "Latvia",
        minZoom: 6,
        maxZoom: 19,
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: function (coords, zoom) {
          // https://gist.github.com/tmcw/4954720
          const y = Math.pow(2, zoom) - coords.y - 1;
          return (
            "https://maps.inkomerc.lv/geoserver/gwc/service/tms/1.0.0/map:background@EPSG:900913@png/" +
            zoom +
            "/" +
            coords.x +
            "/" +
            y +
            ".png"
          );
        },
      },
      microsoft: {
        name: "Bing",
        minZoom: 6,
        maxZoom: 19,
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: function (coords, zoom) {
          function coordsToQuadKey(coords, zoom) {
            var quadkey = "";
            for (var i = zoom; i > 0; i--) {
              var digit = "0";
              var mask = 1 << (i - 1);

              if ((coords.x & mask) != 0) {
                digit++;
              }

              if ((coords.y & mask) != 0) {
                digit++;
                digit++;
              }

              quadkey += digit;
            }
            return quadkey;
          }

          return (
            "https://ecn.t1.tiles.virtualearth.net/tiles/r" +
            coordsToQuadKey(coords, zoom) +
            ".jpeg?g=129&mkt=en-US"
          );
        },
      },
    }
    return mapTypes
  },
  routeStartIcon() {
    return {
      path:
        "M10.099,3.996l0.898,1.453L9.372,6.64L8.447,5.144L7.141,5.952L6.332,4.645L7.64,3.836l0.809,1.306" +
        "L10.099,3.996z M10.181,7.946L9.374,6.64L8.066,7.448l0.807,1.306L10.181,7.946z M17.667,5.115l-0.144,0.312" +
        "c-0.26,1.43-1.146,2.388-1.805,2.795c-1.426,0.881-2.903,0.353-2.964,0.331c-0.958-0.203-1.814-0.087-2.52,0.35" +
        "C8.926,9.712,8.553,11.37,8.55,11.389l-0.087,0.407l5.182,8.378l-0.839,0.521L3.577,5.775C3.4,5.845,3.193,5.787,3.089,5.619" +
        "C2.971,5.428,3.03,5.18,3.22,5.062l0.694-0.428C4.102,4.516,4.352,4.575,4.47,4.766C4.568,4.925,4.537,5.12,4.414,5.251" +
        "l0.249,0.404l0.006-0.013C5.211,4.327,5.901,3.404,6.719,2.9c1.484-0.917,2.84-0.154,2.897-0.121" +
        "c1.16,0.676,1.986,0.458,2.474,0.156c0.209-0.13,0.319-0.256,0.319-0.256c1.146-1.005,1.607-2.143,1.81-2.559" +
        "c0.202-0.415,0.563,0.383,0.563,0.383L17.667,5.115z M14.049,1.209l0.209,0.338l0.513-0.4l-0.41-0.662" +
        "C14.277,0.652,14.201,0.956,14.049,1.209z M8.442,10.6l-0.235,0.146l0.111,0.177C8.35,10.83,8.393,10.717,8.442,10.6z" +
        " M12.691,8.188l-0.18-0.291l-0.176,0.238C12.452,8.148,12.572,8.164,12.691,8.188z M13.958,7.312L13.251,6.17l1.433-1.01" +
        "l-0.809-1.306l-1.431,1.012l-0.839-1.355c-0.168,0.04-0.356,0.064-0.563,0.062L10.1,3.997L9.582,3.163" +
        "C9.534,3.136,9.487,3.112,9.438,3.084C9.422,3.075,9.297,3.005,9.092,2.937L7.639,3.836L7.158,3.061" +
        "C7.075,3.1,6.99,3.147,6.904,3.2C6.569,3.407,6.259,3.697,5.97,4.06l0.361,0.586L5.184,5.354C5.132,5.465,5.081,5.575,5.031,5.693" +
        "l0.707,1.126L7.14,5.952l0.926,1.495L6.675,8.308l0.817,1.3l1.381-0.854l0.351,0.565c0.227-0.264,0.497-0.514,0.825-0.718" +
        "c0.132-0.08,0.269-0.147,0.408-0.21l-0.276-0.446l1.632-1.176l-0.816-1.32l1.445-0.583l0.807,1.305l-1.435,0.598l0.669,1.081" +
        "L13.958,7.312z M17.147,4.951l-0.021-0.034l-0.502,0.456l-0.698-1.126l0.526-0.409l-0.871-1.394L15.117,2.94l-0.858-1.39" +
        "l-0.757,0.468c-0.133,0.176-0.281,0.349-0.438,0.519l0.812,1.315l1.243-0.913l0.807,1.308l-1.241,0.912l0.707,1.142l-1.396,1.072" +
        "l0.576,0.93c0.312-0.065,0.639-0.182,0.961-0.381c0.205-0.127,0.397-0.282,0.576-0.461l-0.717-1.159l1.232-0.929l0.41,0.663" +
        "L17.147,4.951z",
      anchor: { x: 14, y: 20 },
      scale: 1.75,
      fillColor: "#40ff40",
      fillOpacity: 1.0,
      strokeWeight: 1,
      strokeColor: "#000000",
      strokeOpacity: 1.0,
    };
  },

  routeDirectionIcon() {
    return {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 5,
      fillColor: "#ff2020",
      fillOpacity: 1.0,
      strokeWeight: 2,
      strokeColor: "#ffffff",
      strokeOpacity: 0.8,
    };
  },

  routeEndIcon() {
    return {
      path:
        "M10.099,3.996l0.898,1.453L9.372,6.64L8.447,5.144L7.141,5.952L6.332,4.645L7.64,3.836l0.809,1.306" +
        "L10.099,3.996z M10.181,7.946L9.374,6.64L8.066,7.448l0.807,1.306L10.181,7.946z M17.667,5.115l-0.144,0.312" +
        "c-0.26,1.43-1.146,2.388-1.805,2.795c-1.426,0.881-2.903,0.353-2.964,0.331c-0.958-0.203-1.814-0.087-2.52,0.35" +
        "C8.926,9.712,8.553,11.37,8.55,11.389l-0.087,0.407l5.182,8.378l-0.839,0.521L3.577,5.775C3.4,5.845,3.193,5.787,3.089,5.619" +
        "C2.971,5.428,3.03,5.18,3.22,5.062l0.694-0.428C4.102,4.516,4.352,4.575,4.47,4.766C4.568,4.925,4.537,5.12,4.414,5.251" +
        "l0.249,0.404l0.006-0.013C5.211,4.327,5.901,3.404,6.719,2.9c1.484-0.917,2.84-0.154,2.897-0.121" +
        "c1.16,0.676,1.986,0.458,2.474,0.156c0.209-0.13,0.319-0.256,0.319-0.256c1.146-1.005,1.607-2.143,1.81-2.559" +
        "c0.202-0.415,0.563,0.383,0.563,0.383L17.667,5.115z M14.049,1.209l0.209,0.338l0.513-0.4l-0.41-0.662" +
        "C14.277,0.652,14.201,0.956,14.049,1.209z M8.442,10.6l-0.235,0.146l0.111,0.177C8.35,10.83,8.393,10.717,8.442,10.6z" +
        " M12.691,8.188l-0.18-0.291l-0.176,0.238C12.452,8.148,12.572,8.164,12.691,8.188z M13.958,7.312L13.251,6.17l1.433-1.01" +
        "l-0.809-1.306l-1.431,1.012l-0.839-1.355c-0.168,0.04-0.356,0.064-0.563,0.062L10.1,3.997L9.582,3.163" +
        "C9.534,3.136,9.487,3.112,9.438,3.084C9.422,3.075,9.297,3.005,9.092,2.937L7.639,3.836L7.158,3.061" +
        "C7.075,3.1,6.99,3.147,6.904,3.2C6.569,3.407,6.259,3.697,5.97,4.06l0.361,0.586L5.184,5.354C5.132,5.465,5.081,5.575,5.031,5.693" +
        "l0.707,1.126L7.14,5.952l0.926,1.495L6.675,8.308l0.817,1.3l1.381-0.854l0.351,0.565c0.227-0.264,0.497-0.514,0.825-0.718" +
        "c0.132-0.08,0.269-0.147,0.408-0.21l-0.276-0.446l1.632-1.176l-0.816-1.32l1.445-0.583l0.807,1.305l-1.435,0.598l0.669,1.081" +
        "L13.958,7.312z M17.147,4.951l-0.021-0.034l-0.502,0.456l-0.698-1.126l0.526-0.409l-0.871-1.394L15.117,2.94l-0.858-1.39" +
        "l-0.757,0.468c-0.133,0.176-0.281,0.349-0.438,0.519l0.812,1.315l1.243-0.913l0.807,1.308l-1.241,0.912l0.707,1.142l-1.396,1.072" +
        "l0.576,0.93c0.312-0.065,0.639-0.182,0.961-0.381c0.205-0.127,0.397-0.282,0.576-0.461l-0.717-1.159l1.232-0.929l0.41,0.663" +
        "L17.147,4.951z",
      anchor: { x: 14, y: 20 },
      scale: 1.75,
      fillColor: "#ff4040",
      fillOpacity: 1.0,
      strokeWeight: 1,
      strokeColor: "#000000",
      strokeOpacity: 1.0,
    };
  },

  concatVehicleName(first, second, third) {
    let priorityName = first;
    if (second && !third) {
      priorityName += " (" + second + ")";
    } else if (!second && third) {
      priorityName += " (" + third + ")";
    } else if (second && third) {
      priorityName += " (" + second + " " + third + ")";
    }
    return priorityName;
  },
  getPriorityName(vehicle, data) {
    let name = data.name ? vehicle.name : "";
    let regNo = data.regNo ? vehicle.reg_no : "";
    let trailer = data.trailer ? vehicle.trailer : "";
    let priorityName = regNo;

    switch (vehicle.name_1st) {
      case 1: // name
        priorityName = this.concatVehicleName(name, regNo, trailer);
        break;
      case 0: // reg. no
        priorityName = this.concatVehicleName(regNo, trailer, name);
        break;
      case 2: // trailer
        priorityName = this.concatVehicleName(trailer, regNo, name);
        break;
      default:
        priorityName = this.concatVehicleName(regNo, trailer, name);
    }

    return priorityName;
  },
  getMarkerLabelClass(object) {
    return `object-map-label  ${object.status_offline ? " border-gray": ""}`;
  },

  isSafari() {
    let safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return safari;
  },
  isMozillaFirefox() {
    return navigator.userAgent.search("Firefox") === 67
  },
  removeZeros(n) {
    return (+n).toFixed(3).replace(/(\.0+|0+)$/, '')
  },
  formatNum(value, unit) {
    if (this.isSafari()) {
      value = value.toFixed(3);
    }
    if (value === 'kilometer') {
      value = this.removeZeros(value)
    }
    value = new Intl.NumberFormat(store.getters.user.language, {
      style: "unit",
      unit: unit,
      minimumFractionDigits: unit === 'liter' ? 1 : '',
    }).format(value);
    return value;
  },
  convertSize(bytes) {
    if (bytes == null) {
      return '';
    } else if (bytes >= 1073741824) {
      let number = bytes / Math.pow(1024, 3);
      return number.toFixed(2) + 'GB';
    } else if (bytes >= 1048576) {
      let number = bytes / Math.pow(1024, 2);
      return number.toFixed(2) + 'MB';
    } else if (bytes >= 1024) {
      let number = bytes / 1024;
      return number.toFixed(2) + 'KB';
    } else if (bytes > 1) {
      return bytes + ' bytes';
    } else if (bytes == 1) {
      return bytes + ' byte';
    }
    return '0 bytes';
  },
  calculateDurationString(milliseconds) {
    let formattedDurationString = '';
    if (milliseconds == null) {
      return formattedDurationString
    }

    let duration = moment.duration(milliseconds);
    formattedDurationString = moment.utc(duration.as('milliseconds')).format("HH:mm:ss")

    return formattedDurationString;
  },
  dateToUTC(date) {
    const dateTime = moment(date);
    const datetimeMinus3Hours = dateTime.subtract(3, 'hours');
    return datetimeMinus3Hours.format("YYYY-MM-DD HH:mm:ss");
  },
  utcDateToLocal(date) {
    let utcTime = moment.utc(date);
    return moment(utcTime).tz(store.getters.getUserTimezone).format("YYYY-MM-DD HH:mm:ss");
  },
  calculateDiffTimeInMinutes(startTime, endTime) {
    const momentStartTime = moment(startTime);
    const momentEndTime = moment(endTime);

    const timeDiff = momentEndTime.diff(momentStartTime, 'minutes', true);
    return timeDiff
  },
  calculateChunks(totalDuration, chunkDuration) {
    const numFullChunks = Math.floor(totalDuration / chunkDuration);
    const remainingTime = totalDuration % chunkDuration;
    const chunks = [];

    for (let i = 0; i < numFullChunks; i++) {
      chunks.push(chunkDuration);
    }
  
    if (remainingTime > 0) {
      chunks.push(remainingTime);
    }
    return chunks.length;
  }
  
  
};
