<template>
    <div>
        <p class="text-muted text-center mb-1 smallest">{{ data.lat }} - {{ data.lon  }}</p>
        <p class="text-muted text-center small font-weight-bold">{{ data.address.address }}</p>
        
        <div class="d-flex justify-content-between">
            <button class="btn btn-sm btn-success flex-grow-1 mr-1" 
                @click.stop="goToPoint"
            >{{ getTranslationByKey('map.go_to_point') }}</button>

            <button class="btn btn-sm btn-danger flex-grow-1" 
                @click="$emit('close-toast', data.requestId)"
            >{{ getTranslationByKey('vehicles.close') }}</button>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../../bus"
    import { mapGetters } from 'vuex';
    export default {
        props: ["data", "toastId"],
        computed: {
            ...mapGetters(["getTranslationByKey"]),
        },
        mounted() {
            // emit from store while info window close click (to remove toast)
            EventBus.$on('EMY-close-toast', (id) => {
                if (this.toastId == id) {
                    this.$emit('close-toast', id)
                }
            })
        },
        methods: {
            goToPoint() {
                const latLon = { lat: this.data.lat, lng: this.data.lon };
                const id = this.data.requestId
                this.$emit('goToPoint', latLon, id)
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>