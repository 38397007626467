var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-action-buttons p-2 shadow"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"btn btn-light fullscreen-btn",class:{ disabled: _vm.noDataFound },on:{"click":_vm.toggleReportFullscreen}},[_c('i',{staticClass:"fas",class:[
                _vm.reportFullscreen
                ? 'fa-compress-arrows-alt'
                : 'fa-expand-arrows-alt',
            ]})]),_c('button',{staticClass:"btn btn-light",class:{ disabled: _vm.noDataFound },on:{"click":_vm.printReport}},[_c('i',{staticClass:"fas fa-print text-success"})]),_c('button',{staticClass:"btn btn-light",class:{ disabled: _vm.noDataFound },on:{"click":_vm.downloadExcel}},[_c('i',{staticClass:"fas text-muted",class:[
                _vm.excelDownloading
                ? 'fa-spinner fa-spin loading'
                : 'fa-file-excel',
            ]})]),(_vm.pdfButton)?_c('button',{staticClass:"btn btn-light",class:{ disabled: _vm.noDataFound },on:{"click":_vm.downloadPdf}},[_c('i',{staticClass:"fas text-danger",class:[
                _vm.pdfDownloading ? 'fa-spinner fa-spin loading' : 'fa-file-pdf',
            ]})]):_vm._e()]),_c('h2',{staticClass:"report-name d-flex align-items-center ml-n5"},[(_vm.neededCriterisImg)?_c('img',{attrs:{"src":_vm.neededCriterisImg,"alt":""}}):_vm._e(),_c('i',{class:_vm.neededCriterisIcon}),_vm._v(" "+_vm._s(_vm.reportTitle)+" ")]),_c('button',{staticClass:"btn btn-light close-report",on:{"click":_vm.closeReport}},[_c('i',{staticClass:"fa fa-times"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }