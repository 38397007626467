import moment from 'moment';
import api from '../../api';
import helper from '../../helper';
import errorMixin from '../../mixins/handleErrorMixin';
export default {
    state: {
        requestedVideos: [],
        loading: false,
        guidedCurrentStep: 0,
        guidedVideoList: null,
        guidedShowProgress: true,
        guidedAvailableCameras: [],
        guidedAvailableMicrophones: [],
        // chart video list
        videoLists: [],
        videoListRetry: false,
        historyData: [],
        speedToChart: [],
    },
    actions: {
      async requestVideos({commit, state}, collectedData) {
        state.loading = true;

        let requestData = {
          object_id: collectedData.objectId,
          flow_type_name: collectedData.typeName,
          sources: collectedData.sources.join(", "),
          reason: collectedData.description,
          micId: collectedData.micId,
          from: moment(collectedData.period.startTime).format("YYYY-MM-DD HH:mm:ss"),
          till: moment(collectedData.period.endTime).format("YYYY-MM-DD HH:mm:ss"),
        };

        try {
          const response = await api.post("ems/evidence-request", requestData);

          if (response.status === 200) {
            commit('SET_REQUESTED_VIDEOS', {data: response.data.data, collectedData});
          }
        } catch (error) {
          errorMixin.methods.handleErrorMixin(error)
        }
      },
      async getRequestedVideosOnLoad({ commit }) {
        const params = {filter: 'today'}
        try {
          const response = await api.get('ems/evidence-request/actual/my', params);
          if (response.status === 200) {
            if (response.data.data.length) {
              commit('SET_REQUESTED_VIDEOS', {data: response.data.data});
              this.dispatch('WSReceiveVideo');
            }
          }
        } catch (error) {
          errorMixin.methods.handleErrorMixin(error)
        }
      },
      WSReceiveVideo({ commit, state }) {
        const pusher = this._vm.pusher.pusher;
        const { getUid } = this.getters;

        const channel = pusher.subscribe(
          `private-ems-request-updated.${getUid}`
        );
        channel.bind("ems-request-updated", (data) => {
          commit('UPDATE_REQUESTED_VIDEO', data)
        });
      },
      // for chart
      async requestVideoList({ commit }, objectId) {
        try {
          const response = await api.post(`objects/${objectId}/request-available-media`);
          if (response.status === 200) {
            commit('GUIDED_PROGRESS_SHOW', true)
          }
        } catch (error) {
          errorMixin.methods.handleErrorMixin(error)
        }
      },
      WSReceiveVideoLists({commit, state}, objectId) {
        commit('SET_GUIDED_CURRENT_STEP', 0);
        const pusher = this._vm.pusher.pusher;
        const { getUid } = this.getters;
        const channel = pusher.subscribe(
            `private-ems-request-list-state.${getUid}.${objectId}`
        );
        channel.bind("ems-request-list-state", (data) => {
          commit('UPDATE_CURRENT_STEP', data.status);
          commit('SET_VIDEO_LISTS', data.videoData)
          commit('SET_AVAILABLE_MICROPHONES', data.availableMicIds)
          // commit('SET_HISTORY_DATA', data.historyData);
        });
      },
    },
    mutations: {
      SET_REQUESTED_VIDEOS(state, {data, collectedData}) {
        const requestedData = data;
        const { translations:trans } = this.getters;
        let requestTime = new Date().toLocaleString();

        for (const row of requestedData) {
          if (!state.requestedVideos.includes(row.id)) {
            state.requestedVideos.push({
              id: row.id,
              object: row.object_title,
              source: row.requested_source,
              from: moment(row.requested_datetime_from).format("YYYY-MM-DD HH:mm:ss"),
              to: moment(row.requested_datetime_till).format("YYYY-MM-DD HH:mm:ss"),
              duration: helper.calculateDurationString(row.duration),
              loadedSize: helper.convertSize(row?.loaded_size),
              size: helper.convertSize(row?.size),
              percent: Math.round(row.file_ready_percent),
              nextTryAt: row.next_try_at,
              statusName: row.status_name,
              requestedAt: row.created_at,
              fileName: '',
              urlDownload: row.url_download
            });
          }
        }
        state.loading = false;
      },
      UPDATE_REQUESTED_VIDEO(state, data) {
        const { 
          id,
          percent,
          statusName,
          fileName,
          statusMessage,
          nextTryAt,
          size,
          loadedSize,
          duration
        } = data;
        const updatedPercentage = Math.round(percent);

        let foundVideo = state.requestedVideos.find(row => row.id == id);

        if (foundVideo) {
          foundVideo.percent = updatedPercentage;
          foundVideo.statusName = statusName;
          foundVideo.fileName = fileName;
          foundVideo.nextTryAt = nextTryAt;
          foundVideo.size = helper.convertSize(size);
          foundVideo.loadedSize = helper.convertSize(loadedSize);
          foundVideo.duration = helper.calculateDurationString(duration);
        }
      },
      REMOVE_CANCELED_VIDEO(state, videoId) {
        const index = state.requestedVideos.findIndex(row => row.id == videoId);
        state.requestedVideos.splice(index, 1);
      },
      UPDATE_CURRENT_STEP(state, status) {
        if (state.videoListRetry) {
          state.videoListRetry = false;
        }
        switch (status) {
          case "registered":
            this.commit("SET_GUIDED_CURRENT_STEP", 1);
            break;
          case "received":
            this.commit("SET_GUIDED_CURRENT_STEP", 2);
            break;
          case "processing":
            this.commit("SET_GUIDED_CURRENT_STEP", 3);
            break;
          case "complete":
            this.commit("SET_GUIDED_CURRENT_STEP", 4);
            setTimeout(() => { this.commit("GUIDED_PROGRESS_SHOW", false) }, 1500)
            break;
          case "error":
          case "timeout":
            state.videoListRetry = true;
            this.commit("SET_GUIDED_CURRENT_STEP", 0);
            break;
          default:
            this.commit("SET_GUIDED_CURRENT_STEP", 0);
        }
      },
      GUIDED_PROGRESS_SHOW(state, status) {
        state.guidedShowProgress = status;
      },
      SET_GUIDED_CURRENT_STEP(state, currentStep) {
        state.guidedCurrentStep = currentStep;
      },
      SET_VIDEO_LISTS(state, videoLists) {
        state.videoLists = videoLists;
        state.guidedAvailableCameras = [];
       state.videoLists.forEach(c => {
          if (c.timeList.length > 0) {
            state.guidedAvailableCameras.push({id: c.id, name: c.name, visible: false})
          }

        })
        if (state.guidedAvailableCameras.length > 0) {
          state.guidedAvailableCameras[0].visible = true
        }
      },
      SET_AVAILABLE_MICROPHONES(state, microphones) {
        state.guidedAvailableMicrophones = microphones
      },
      SET_HISTORY_DATA(state, historyData) {
        state.historyData = historyData;
      },
      // SET_SPEED(state) {
      //   state.speedToChart = []
      //   const startingVideo = moment(state.videoLists[0].timeList[0].from * 1000);
      //   const lastEl = state.videoLists[state.videoLists.length - 1];
      //   let endingVideo;

      //   if (lastEl.timeList.length) {
      //     endingVideo = moment(lastEl.timeList[lastEl.timeList.length - 1].till * 1000);
      //   } else {
      //     for (let i = state.videoLists.length -1; i >= 0; i--) {
      //       const obj = state.videoLists[i];
      //       if (obj.timeList.length) {
      //         endingVideo = moment(obj.timeList[obj.timeList.length - 1].till * 1000);
      //         break;
      //       }
      //     }
      //   }

      //   state.speedToChart = state.historyData.filter(obj => {
      //     const txdt = moment(obj.txdt);
      //     return txdt.isBetween(startingVideo, endingVideo, 'second')
      //   });
      // },
    },
    getters: {
      getRequestedVideos(state) {
        return state.requestedVideos;
      },
      getRequestedVideosLoading(state) {
        return state.loading;
      },
      getGuidedProgressCurrentStep(state) {
        return state.guidedCurrentStep;
      },
      getGuidedShowProgress(state) {
        return state.guidedShowProgress;
      },
      getVideoLists(state) {
        return state.videoLists;
      },
      getGuidedAvailableCameras(state) {
        return state.guidedAvailableCameras;
      },
      getGuidedAvailableMicrophones(state) {
        return state.guidedAvailableMicrophones
      },
      getVideoListRetry(state) {
        return state.videoListRetry;
      },
      getHistoryData(state) {
        return state.historyData;
      },
      getSpeedToChart(state) {
        return state.speedToChart;
      }
    }
}