<template>
    <div>
        <div class="d-flex flex-nowrap align-items-center">
            <span class="minor-text criteria-header mr-5">
                {{ getTranslationByKey('reports.criterias.select') }} 
                {{ getTranslationByKey('reports.criterias.object') }}:
            </span
            >
            <div class="criteria-content flex-wrap flex-md-nowrap">
                <button
                    class="toolbar-btn mr-2 select-btn py-3 px-3"
                    @click="toggleModal"
                >
                    <i class="fas fa-list-ol mr-2"></i>
                    <span class="small">
                        {{ getTranslationByKey('reports.criterias.select') }}
                    </span>
                </button>
            </div>
        </div>

        <objects-base-modal
            v-if="isShownObjModal" 
            :treeOptions="treeOptions"
            @modalClosed="modalClosed"
            @onOkButtonPressed="onOkButtonPressed"
        >
        </objects-base-modal>
    </div>
</template>

<script>
import ObjectsBaseModal from '../global/ObjectsBaseModal.vue';
import { mapGetters } from 'vuex';
    export default {
        props: {
            index: {
                type: Number
            }
        },
        components: {
            ObjectsBaseModal,
        },
        data() {
            return {
                isShownObjModal: false,
                treeOptions: {
                    propertyNames: {
                        text: 'title',
                        children: 'items'
                    },
                    urlFilter: {
                        filter: 'my,pc',
                        with: 'objects'
                    },
                    checkbox: true,
                    checkOnSelect: true,
                    singleMode: true,
                },
            }
        },
        computed: {
            ...mapGetters(["getTranslationByKey"])
        },
        methods: {
            toggleModal() {
                this.isShownObjModal = !this.isShownObjModal;
            },
            modalClosed() {
                this.toggleModal()
            },
            onOkButtonPressed(selectedVehicles) {
                selectedVehicles[0].index = this.index;
                this.$emit('onSelectedVehicle', selectedVehicles);
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>