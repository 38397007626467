var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-grow-1 align-items-center"},[_c('div',{staticClass:"object-status-color-mark",style:({ 'background-color': _vm.object.status_color })}),_c('div',{staticClass:"objects-list-name",on:{"click":_vm.panToMap}},[_vm._v(" "+_vm._s(_vm.object.priority_name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.object.status_offline && _vm.object.can_tachograph),expression:"!object.status_offline && object.can_tachograph"}],staticClass:"objects-list-tachograph"},_vm._l((_vm.object.tachograph_img),function(img,index){return _c('div',{key:index,staticClass:"objects-list-tachograph-icon"},[_c('img',{class:[
          img === 'tachograph-green' ||
          img === 'tachograph-yellow' ||
          img === 'tachograph-red'
            ? 'custom-flag'
            : '',
        ],attrs:{"src":'/img/icons/16x16/' + img + '.svg',"title":_vm.tachographTitle(img)}})])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.object.beacons || _vm.object.siren),expression:"object.beacons || object.siren"}],staticClass:"object-list-siren-status"},[_c('img',{attrs:{"src":_vm.beaconsStatus,"title":_vm.beaconsStatusTitle}})]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLowVCCBattery),expression:"isLowVCCBattery"}],staticClass:"fa fa-car-battery mr-1 text-danger objects-list-vcc-status",attrs:{"title":_vm.getTranslationByKey('vehicles.tooltip.vcc_battery_low')}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.object.pc && !_vm.object.status_offline),expression:"object.pc && !object.status_offline"}],staticClass:"objects-list-computer-status"},[_c('i',{staticClass:"fas fa-tv",class:_vm.pcStatus,attrs:{"title":_vm.computerStatusTitle}})]),_c('div',{staticClass:"objects-list-status-icon"},[_c('div',{staticClass:"d-flex",class:_vm.object.status_wrapper_class,attrs:{"title":_vm.objectStatusTitle}},[_c('i',{staticClass:"fas",class:_vm.object.status_icon_class})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }